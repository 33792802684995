.serviceDetails{
    margin: 30px 0;
    border: 1px solid #ebebeb;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    &__img img{
        width: 100%;
        height: 450px;
        object-fit: cover;
    }
    &__content,
    &__contact {
        padding: 30px;
    }
    &__sidebar{
        padding: 30px;
        border-left: 1px solid #ebebeb;
        & h3{
            text-transform: capitalize;
        }
    }
    &__sidebar li{
        padding: 10px 10px 5px;
    }
    &__sidebar li a,
    &__sidebar li span{
        color: #000;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.5;
        display: block;
        padding: 10px 0;
        border-bottom: 1px solid #ebebeb;
    }
    &__item{
        padding-bottom: 30px;
    }
    &__contact li i{
        color: #0042A6;
        padding-right: 5px;
    }
}